import React, { useState } from "react";

import NavItems from "./NavItems";

function Navbar() {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div>
      <div className="nav fixed flex flex-col w-full h-auto bg-black bg-opacity-30 top-0 text-white py-3 px-5 z-50">
        <div className="max-w-screen-xl ml-auto">
          <div className="relative w-full flex flex-col md:flex-row justify-end text-opacity-100">
            <button className="md:hidden" onClick={handleClick}>
              {!nav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 9h16.5m-16.5 6.75h16.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
            <div className="hidden md:block">
              <NavItems />
            </div>
          </div>
        </div>
        <nav
          className={
            !nav
              ? "hidden"
              : "absolute mt-9 right-0 block bg-black bg-opacity-50 rounded-l-b p-3 z-50 md:hidden"
          }
        >
          <NavItems />
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
