import React from "react";

function Bio() {
  return (
    <div className="section">
      <div id="bio" className="container">
        <h1>Bio</h1>
        <p>
          Kara writes suspense novels with the goal of keeping you up at night.
          Her characters often believe in witchcraft, magic, and parallel
          universes and she challenges the reader to question if maybe, just
          maybe, they believe too.
        </p>
        <p>
          Raised on a small farm in Virginia, Kara has worked many gigs—from
          bartending in Boulder to selling art on the streets in Soho (NY) to
          writing a long-running food column to coding websites. She and her
          husband, author{" "}
          <a href="https://andrewamelinckx.com/">Andrew Amelinckx</a>, live in
          the Hudson Valley with their dog Dashiell.
        </p>
      </div>
    </div>
  );
}

export default Bio;
