import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Bio from "./components/Bio";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="bg-[#1B191B] relative flex flex-col font-body scroll-smooth">
      <Navbar />
      <Hero />
      <Bio />
      <Contact />
    </div>
  );
}

export default App;
