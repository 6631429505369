import React from "react";

function Contact() {
  return (
    <div>
      <div className="section">
        <div id="contact" className="container">
          <h1>Contact</h1>
          <h3>Social Media:</h3>
          <a href="https://www.instagram.com/karathurmond/">
            Instagram: @karathurmond
          </a>
          <a href="https://www.linkedin.com/in/karathurmond/">
            Linkedin: @karathurmond
          </a>

          <a href="https://discord.com/">Discord: @karathurmond#8318</a>
          {/* <h3 className="mt-5">Representation:</h3> */}
        </div>
      </div>{" "}
    </div>
  );
}

export default Contact;
