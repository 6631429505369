import React from "react";
import { Link } from "react-scroll";
import { useState } from "react";

function NavItems() {
  const [nav, setNav] = useState(false);
  const closeNav = () => setNav(!nav);
  return (
    <div className="flex flex-col md:flex-row md:space-x-5 uppercase text-white text-sm tracking-wider text-right font-thin px-3">
      <Link
        to="hero"
        spy={true}
        smooth={true}
        offset={0}
        duration={600}
        className="cursor-pointer"
        onClick={closeNav}
      >
        Home
      </Link>
      <Link
        to="bio"
        spy={true}
        smooth={true}
        offset={-100}
        duration={600}
        className="cursor-pointer"
        onClick={closeNav}
      >
        Bio
      </Link>

      <Link
        to="contact"
        spy={true}
        smooth={true}
        offset={0}
        duration={600}
        className="cursor-pointer"
      >
        Contact
      </Link>
    </div>
  );
}

export default NavItems;
