import React from "react";
import Video from "../assets/river-bg.mp4";

function Hero() {
  return (
    <div>
      <div id="hero" className="relative">
        <div className="right-0 left-0 top-0 w-full h-full z-10">
          <video
            className="object-cover w-screen h-screen bg-bottom shadow-lg brightness-75"
            src={Video}
            autoPlay
            loop
            muted
            playsInline
            type="video/mp4"
          />
        </div>
        <div className="absolute right-0 left-0 top-6 sm:top-0 w-full h-screen flex items-center flex-col justify-center xl:pt-20 z-40 overflow-x-0">
          <h1 className="font-header leading-[.85] text-[3.6rem] md:text-[5rem] text-center uppercase font-thin text-white text-shadow py-1 z-50">
            Kara Thurmond
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Hero;
